.login-title {
    padding-top: 2rem;
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 1rem;
}

.instructions {
    width: 100%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 1.2em;
    font-weight: 400;
}

.instructions a {
    font-size: 1em;
    font-weight: 550;
}

.login-container {
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    padding: 2rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-form p {
    text-align: center;
}

.login-form p,
.login-form input {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    display: block;
}

.login-form button {
    width: 100px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.third-party-container {
    border-top: 1px solid #ccc;
    margin-top: 2rem;
    text-align: center;
}

.message {
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 5px;
    background-color: #eab671a1;
}

.message.error {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}