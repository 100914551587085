.rich-table-header-cell .sort-column {
  cursor: pointer;
}

.rich-table-header-cell .sort-icon {
  width: 1rem;
  color: black;
}

.rich-table-header-cell {
  vertical-align: top;
}

.rich-table-header-cell .sort-icon.unsorted {
  color: #aaa;
}

.rich-table-header-cell-title {
  padding-right: 4px;
}

.rich-table-page-nav {
  margin: 2px;
}

.rich-table-page-nav-ico {
  cursor: pointer;
  width: 1rem;
}

.rich-table .hidden {
  display: none;
}

.rich-table.fixed {
  table-layout: fixed;
}

.rich-table.table-condensed .rich-table-header-row,
.rich-table.table-condensed .rich-table-body-row {
  line-height: 12px;
}

.rich-table-filter {
  margin-top: 5px;
}

.rich-table-no-data-message {
  text-align: center;
  padding: 10px;
}

.double-chevron {
  white-space: nowrap;
}