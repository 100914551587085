* {
    font-size: 13px;
    font-family: Helvetica;
}

.smaller-text {
    font-size: 11px;
}

.small-text {
    font-size: 10px;
}

.smallest-text {
    font-size: 9px;
}

.bold {
    font-weight: bold;
}

.bold-big {
    font-weight: bold;
    font-size: 16px;
}

.tableRow {
    line-height: 10px;
}

.sameline {
    white-space: nowrap;
}

.sameline>div {
    display: inline-block;
}

.tableRowCondensed {
    line-height: 10px;
    padding: 0px 0px 0px 0px;
}

.tableRowBold {
    line-height: 10px;
    font-weight: bold;
}

input[type=text] {
    padding: 0px 0px 0px 0px;
}

tr:nth-child(even) {
    background-color: rgb(212, 246, 252);
}

.button {
    background-color: #04AA6D;
    border: 1px;
    color: white;
    text-align: center;
    font-weight: bold;
    display: inline-block;
}

a {
    text-decoration: none;
}

.tooltip-link {
    font-weight: 600;
}

.plus-minus-button {
    font-weight: bold;
    font-size: 20px;
    padding-left: 3px;
    padding-right: 3px;
    text-decoration: none;
}

.big-link {
    font-weight: bold;
    font-size: 15px;
    text-decoration: none;
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    top: 10px;
    right: 5%;
}


/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #BFBFBF;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    border-left: 3px solid black;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #000000;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
    color: #373a47;
}

.tc-footer {
    padding: 25px;
    font-size: 9px;
}

.menu-item {
    color: #373a47;
}

.nav-section {
    padding-top: 1.2rem;
}

.nav-header {
    color: #373a47;
    font-weight: bold;
}

.nav-menu-item {
    display: block;
}

.social-icon {
    height: 32px;
}

.bold {
    font-weight: bold;
}

.empty-page {
    margin-top: 20px;
    margin-bottom: 300px;
}

:root {
    --right-margin-normal: 20px;
    --right-margin-nav-docked: 250px;

    --current-right-margin: var(--right-margin-normal);
}

.main-content {
    padding-top: 30px;
    margin-top: 65px;
    margin-left: 20px;
    margin-bottom: 50px;
    margin-right: var(--current-right-margin);
}

.dropdown {
    width: 150px;
}

.monthly-table {
    table-layout: fixed;
}

.droptown-toggle {
    width: 150px;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.collapsible .content {
    padding: 6px;
    background-color: #eeeeee;
}

.collapsible .header {
    background-color: #dddddd;
    padding: 6px;
    cursor: pointer;
}

.topheader {
    background-color: #A0DDFD;
    position: fixed;
    width: "100%";
    top: 0;
    right: 0;
    left: 0;
    border-bottom: 2px solid black;
    z-index: 100;
}

.plan-box {
    background-color: #B2D8B2;
    border: 2px solid black;
    width: 150px;
    height: 50px;
    text-align: center;
    padding: 5px;
    font-weight: bold;
}

.help-button {
    position: fixed;
    bottom: 60px;
    right: 0px;
    padding: 10px;
}

.addalgo-button {
    position: fixed;
    bottom: 60px;
    left: 50px;
    padding: 10px;
    z-index: 10;
}

.runtest-button {
    position: fixed;
    top: 60px;
    left: 50px;
    padding: 10px;
    z-index: 10;
}

.saveAllocations-button {
    position: fixed;
    top: 60px;
    left: 100px;
    padding: 10px;
    z-index: 10;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

.footer {
    background-color: #A0DDFD;
    border-top: 2px solid black;
    position: fixed;
    width: '100%';
    left: 0;
    right: 0;
    bottom: 0;
    height: 60px;
    z-index: 10;
    padding-top: 5px;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.row-bg-transparent {
    background-color: 'transparent';
    color: 'transparent';
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
}

.popup-content {
    z-index: 5000 !important;
}