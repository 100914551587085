.stats-table {
  border-collapse: collapse;
  margin-right: auto;
}

.stat-cell {
  font-size: 14pt;
  min-width: 100px;
}

.stat-title {
  padding: 10px;
}

.stat-value {
  padding: 10px;
  text-align: right;
}